import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Appbar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            minHeight: 80,
          },
        },
      },
    },
  };
}
