import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Toolbar(theme: Theme) {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
          },
          [theme.breakpoints.up('sm')]: {
            minHeight: 80,
          },
        },
      },
    },
  };
}
