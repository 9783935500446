// scroll bar
import 'simplebar/src/simplebar.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

//lightbox
import 'react-image-lightbox/style.css';

import { ReactElement, ReactNode, useEffect } from 'react';
// next
import Head from 'next/head';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
// contexts
import { SettingsProvider } from 'src/contexts/SettingsContext';
import { CollapseDrawerProvider } from 'src/contexts/CollapseDrawerContext';
// theme
import ThemeProvider from 'src/theme';
import GlobalStyles from 'src/theme/globalStyles';
// components
import RtlLayout from 'src/components/RtlLayout';
import ProgressBar from 'src/components/ProgressBar';
import ThemeColorPresets from 'src/components/ThemeColorPresets';
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer';
import NotistackProvider from 'src/components/NotistackProvider';

// google analytics
import { GoogleAnalytics, usePageViews } from 'nextjs-google-analytics';

//polyfill for smooth scroll (to make smooth scroll work on mobile)
import { RecoilRoot } from 'recoil';

//css import
import './app.css';
// import '@fullcalendar/common/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
// import '@fullcalendar/list/main.css';

import { useRouter } from 'next/router';
// ----------------------------------------------------------------------

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
}

export default function MyApp(props: MyAppProps) {
  const router = useRouter();

  const { Component, pageProps } = props;

  usePageViews();

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('561429966119933');
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <GoogleAnalytics gaMeasurementId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID} />
      </Head>
      <RecoilRoot>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <ThemeProvider>
              <ThemeColorPresets>
                <RtlLayout>
                  <NotistackProvider>
                    <MotionLazyContainer>
                      <GlobalStyles />
                      <ProgressBar />
                      {getLayout(<Component {...pageProps} />)}
                    </MotionLazyContainer>
                  </NotistackProvider>
                </RtlLayout>
              </ThemeColorPresets>
            </ThemeProvider>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </RecoilRoot>

      {/* <MessengerCustomerChat
        themeColor="#F15B40"
        pageId="1841315256092551"
        appId="1913729215620894"
      /> */}
    </>
  );
}
